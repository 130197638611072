<template>
  <div
    v-if="competitionItems && competitionItems.isState === 1"
  >
    <!-- 공모전명 -->
    <div class="title-wrap">
      <h5>{{ competitionItems.competitionTitle }}</h5>
    </div>

    <section class="tab-contents-wrap">
      <section class="report_group" style="width: 100%">
          <section class="report_box">
            <div class="contest-apply step2">
              <div class="form">
                <form name="">
                  <template v-for="(item, idx) in competitionItems.competitionItems">
                    <div class="title">{{ item.name }}</div>
                    <table>
                      <tbody>
                        <template v-for="(info, infoIndex) in item.questions">
                          <tr>
                            <th>{{ info.question }}</th>
                          </tr>
                          <tr>
                            <td v-if="info.type != 4">
                              {{ info.answer }}
                            </td>
                            <td v-else>
                              <template v-for="(file, inputIdx) of info.answers">
                                <span v-if="inputIdx>0">&nbsp;/&nbsp;</span>{{ file.fileName || '-' }}
                              </template>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </template>
                  <table v-if="competitionItems.competitionUseMarketing">
                    <tbody>
                      <tr>
                        <th>{{ $t('content.accelerating.academy.DisplayInfo.txt11') }}</th>
                      </tr>
                      <tr>
                        <td v-if="competitionItems.isUseMarketing">{{ $t('content.accelerating.academy.DisplayInfo.txt12') }}</td>
                        <td v-else>{{ $t('content.accelerating.academy.DisplayInfo.txt13') }}</td>
                      </tr>
                    </tbody>
                  </table>
              </form>
            </div>
          </div>
        </section>
      </section>
    </section>
    <!-- 버튼 -->
    <div class="paging-area">
      <ul class="paging">
        <li>
          <BackButton
            @click="doPrev"
          />
        </li>
      </ul>
    </div>
    <!-- // 버튼 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import routeTypes from '@/router/routeTypes';
import BackButton from '@/components/base/BackButton.vue';
import ApplyInfo from '../component/ApplyInfo.vue';
import ApplyStatus from '../enum/ApplyStatus';
import ProgramsApplicationService from '../service/ProgramsApplicationService';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 내역 (탭) : 지원서 보기
 */
export default {
  name: 'ProgramsMyApplyDetail',
  mixins: [mixinHelperUtils],
  components: {
    ApplyInfo,
    BackButton,
  },

  props: {
    /** 지원서 보기를 위한 (별도의) ACC지원프로그램 관리번호  */
    anotherAccPgmMngNoString: {
      type: String,
      required: true,
    },
    /** 지원서 보기를 위한 (별도의) 지원 고유번호 */
    anotherAccNoString: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      /**
       * Acc지원 정보
       * @type {import('../dto/AccApplyDto').default}
       */
      accApply: undefined,

      /**
       * Acc지원프로그램 관리 상세
       * @type {import('../dto/AccApplyPgmMngDetailDto').default}
       */
      accApplyPgmMngDetail: undefined,

      userInfo: null,
      accApplyTemporary: null,
      competitionItems: null,
      keyIndex: 1,
    };
  },
  computed: {
    accPgmMngNo() {
      return +this.anotherAccPgmMngNoString;
    },
    accNo() {
      return +this.anotherAccNoString;
    },
  },

  async created() {
    this.userInfo = this.$store.getters.getUserInfo();
    console.log("anotherAccPgmMngNoString", this.anotherAccPgmMngNoString)
    console.log("anotherAccNoString", this.anotherAccNoString)
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);
    this.competitionItems = await this.programsApplicationService.getAccApplyPgmMngItemAnserList(this.accPgmMngNo, this.userInfo.id);
    
    // this.competitionItems = await this.programsApplicationService.getAccApplyPgmMngItemList(this.accPgmMngNo);
    // const tmp = await this.programsApplicationService.getTempAccApplyMember(this.accPgmMngNo, this.userInfo.id);
    // this.accApplyPgmMngDetail = tmp;
    // this.accApplyTemporary = tmp.items
    // this.setAnswer()

  },

  methods: {
    doPrev() {
      // kstartup 1dep이여서 수정함.
      this.$router.go(-1);
      // 공모 지원 내역으로 이동
      // this.$router.replace({
      //   name: 'ProgramsMyApplyList',
      // });
    },

    setAnswer() {
      if (this.accApplyTemporary.length > 0) {
        for (const item of this.competitionItems) {
          for (let info of item.infos) {
            if (info.type == 1) {
              info.inputs[0].answer = this.findRadioAnswer(info.competitionId, info.competitionItemId, info.id, 0)
            } else if (info.type == 2) {
              for (let input of info.inputs) {
                input.answer = this.findRadioAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
              }
            } else if (info.type == 3) {
              for (let input of info.inputs) {
                input.answer = this.findAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
              }
            } else if (info.type == 4) {
              for (let input of info.inputs) {
                const tmp = this.findFileAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
                if (tmp) {
                  input.fileName = tmp.fileName
                  input.answer = tmp.answer
                }
              }
            }
          }
        }
      }
      ++this.keyIndex
    },
    findCheckAnswer2(idx, infoIdx) {
      console.log('findCheckAnswer2(competitionId, competitionItemId, competitionItemInfoId, id)')
      const inputs = this.competitionItems[idx].infos[infoIdx];
      console.log(inputs)
      const answers = []
      for (const tmp of inputs.inputs) {
        if (tmp.answer === 1) {
          answers.push( tmp.name )
        }
      }
      return answers.join(" / ");
    },
    findRadioAnswer2(idx, infoIdx, id) {
      console.log('findRadioAnswer2(competitionId, competitionItemId, competitionItemInfoId, id)', idx, infoIdx, id)
      const inputs = this.competitionItems[idx].infos[infoIdx];
      for (const tmp of inputs.inputs) {
        if (tmp.id === id) {
          return tmp.name;
        }
      }
      return null;
    },
    findRadioAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          if (tmp.answer) return tmp.answer*1;
        }
      }
      return null;
    },
    findAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          console.log('findAnswer', tmp.answer)
          if (tmp.answer) return  tmp.answer;
        }
      }
      return null;
    },
    findFileAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          console.log('findAnswer', tmp.answer)
          if (tmp.answer) return  {answer: tmp.answer, fileName: tmp.fileName};
        }
      }
      return null;
    },



  },
};
</script>
<style>
.form-list .list-title {
  min-width: 200px !important
}
.checkbox-container {
  display: inline-block !important;
  margin-right: 80px !important;
  margin-top: 0px !important;
}
.error {
  border-color: #ff5013 !important; 
}
select {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.57 !important;
  padding: 11px 15px !important;
}
.contest-apply.step2 .form table th {
  width: 200px !important
}
@media screen and (min-width: 769px) {
  .contest-apply {
    margin-top: 10px;
    padding: 20px 104px 10px;
  }
  .contest-apply.step2 .title {
    margin-top: 10px;
  }
  .contest-apply.step2 .form table {
    margin-bottom: 20px;
  }
  .step-wrap {
    margin: 20px 0;
  }
}
.input-error {
  border: solid 1px #ff5013 !important;
}
.input-error-desc {
  margin-top: 2px !important;
  margin-bottom: 10px;
}
</style>